/*
data-zc-tooltip is an attribute that gets set on the root tooltip element;
this proved to be the only way to target the .MuiTooltip-tooltip child node
as done below here, in order to remove the width/max-width settings. Note:
passing 'class' property to the PopperProps (see associated Tooltip component)
overrides *all* the classes, which are still necessary for material-ui.
*/
[data-zc-tooltip] {
  width: auto;
  max-width: none;

  .MuiTooltip-tooltip {
    width: auto;
    max-width: none;
  }
}

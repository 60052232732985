.dialog-user-picker-searcher-thing {
}

.dialog-user-picker-searcher-thing h5 {
  font-size: 105%;
  text-align: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
}

.dialog-user-picker-searcher-thing .pick-user {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}

.dialog-user-picker-searcher-thing .pick-user span.display-email {
  display: block;
  font-size: 92%;
  opacity: 0.7;
}

.dialog-user-picker-searcher-thing .pick-user small {
  display: block;
}

#credential-manager {
  .grid-row-margin-bottom {
    margin-bottom: 1rem;
  }
  .private-key-input {
    .MuiOutlinedInput-root {
      background: #111;
    }
    label {
    }
    textarea {
      color: #fff;
      background: #111;
      font-family: monospace;
      font-size: 12px;
    }
  }
  .key-validation-info {
    margin-bottom: 1rem;
    .key-validation-error {
      white-space: pre;
      background: #d32f2f;
      color: #fff;
      padding: 0.5rem;
      border-radius: 8px;
    }
  }
  // not nested under parent class since this is used in other components as well
  .key-validation-display {
    font-size: 85%;
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-row-gap: 0.5rem;
    align-items: center;
    .val-label {
      white-space: nowrap;
      font-weight: bold;
      display: inline-block;
      padding-right: 0.5rem;
      text-align: right;
    }
    .val-cell {
      display: inline-block;
      padding: 0.5rem;
      background: #e1e1e1;
      white-space: pre;
      font-family: monospace;
      overflow: hidden;
      overflow-x: scroll;
      border-radius: 5px;
    }
  }
  .connection-check-errs {
    display: block;
    margin-top: 1rem;
    white-space: pre;
    background: #d32f2f;
    color: #fff;
    padding: 0.5rem;
    border-radius: 8px;
  }
}

.tr-error {
  background: rgb(255, 152, 152) !important;
}

.tr-warning {
  background: rgba(255, 204, 0, 0.3) !important;
}

.tr-disabled {
  background: #ccc;
  color: #888;
}

.tr-disabled input[type='checkbox'] {
  display: none;
  visibility: hidden;
}

.tr-saved {
  -webkit-animation-name: success-animation;
  -webkit-animation-duration: 3s;
  -webkit-animation-timing-function: ease-out;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-play-state: running;

  animation-name: success-animation;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-play-state: running;
}

tr > td > div > span {
  cursor: text;
}

tr > td > div {
  display: inline-block;
  cursor: text;
}

tr > td > div > a {
  cursor: pointer;
}

tr > td {
  cursor: pointer;
}

.legend {
  margin-bottom: -25px;
}

.legend-item {
  float: left;
  width: 20px;
  height: 20px;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.yellow {
  background: rgba(255, 204, 0, 0.3);
}

@-webkit-keyframes success-animation {
  0% {
    background-color: #009688;
  }
  100.0% {
    background-color: #fff;
  }
}

.invoices-landing {
  overflow: hidden;
  padding: 1rem;
  .tab-wrap {
    background: #263f52;
    color: #fff;
    margin: -1rem -1.5rem 1.5rem;
    .MuiTabs-root {
      .MuiTab-wrapper {
        white-space: nowrap;
      }
    }
  }

  .invoice-bulk-actions {
    background: #e1e1e1;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    margin-top: 0.5rem;

    span.action-label {
      margin-right: 1rem;
    }

    .reminders-button {
      margin-left: 10px;
    }
  }

  .last-bulk-operation-ids {
    background: #263f52;
    color: #fff;
    padding: 1rem;
    text-align: center;
    border-radius: 4px;
    margin: 0.5rem 0;
  }

  .wrap-batch-notifier {
    max-width: 400px;
    margin: 0 auto;
    padding-bottom: 1.5rem;
  }

  .invoice-table-wrap {
    .invoice-table-header-items-right {
      .filter-option {
        display: inline-block;
        margin-left: 0.5rem;
      }
    }
  }
}

.overlay-emp-billing-config {
  max-width: 300px;
  padding: 1rem;

  .MuiGrid-container {
    display: block;
    margin: 0;
    width: auto;

    .MuiGrid-root {
      max-width: 100%;
    }
    .MuiGrid-item {
      max-width: 100%;
    }
  }
}

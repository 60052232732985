#practice-facilities-import {
  .file-picker-btn {
    input {
      display: none;
    }
  }

  .skip-field-btn {
    font-size: 85%;
    margin-top: 0.35rem;
    white-space: nowrap;

    svg {
      max-width: 18px;
    }
  }

  .checkbox-control {
    margin-bottom: 0;
  }

  .csv-errors {
    h4 {
      margin-top: 0;
    }
    ul {
      background: #e7aaaa;
      padding: 1rem 1rem 1rem 2rem;
      border-radius: 8px;
    }
  }

  .csv-results {
    .thead-wrapper {
      display: flex;
      justify-content: space-between;
    }
    .cell-is-new {
      background: #90ee90;
      white-space: nowrap;
    }
    .cell-is-match {
      background: #d8d842;
      white-space: nowrap;
    }
  }

  .import-report {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0.35rem;

    strong {
      display: inline-block;
      white-space: nowrap;
    }
  }

  section {
    padding: 1rem 0 0;

    h4 {
      margin-bottom: 0.5rem;
      margin-top: 0;
    }
  }

  .dialog-action-is-working {
    justify-content: center;
    padding-bottom: 1rem;
  }
}

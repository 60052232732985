.duplicates-dialog {
  .duplicate-list {
    display: grid;
    grid-template-columns: repeat(3, auto);
    margin-bottom: 1rem;
    div {
      padding: 0.5rem;
    }
    .duplicate-list-label {
      font-weight: 500;
      font-size: 80%;
    }
  }
  blockquote {
    font-size: 100%;
    background: #ff4a4a;
    color: #fff;
    margin-bottom: 0;
    border-radius: 8px;
    padding: 1rem 1rem 1.5rem;
    sub {
      display: block;
      line-height: 1;
    }
  }
}

.adhoc-queued-files {
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  align-items: center;
  .headers {
    padding: 0.5rem;
    font-weight: bold;
  }
  .cell {
    padding: 0.5rem;
  }
}

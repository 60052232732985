/*@import url("styles/colors.css");*/

/*for zero*/
body {
  margin: 0;
  padding: 0;
  font-family: arial;
  font-size: 12px;
  background: #fff;
}

html {
  font-size: 16px !important;
}

a:hover {
  text-decoration: none !important;
}

span .caret {
  display: none;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 0ms ease-in;
}

html,
body,
#root {
  height: 100vh;
  width: 100vw;
}

body {
  // env-mode-prod
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  overscroll-behavior-y: none;
  background: linear-gradient(123deg, #f2f2f2, #acacac);
  background-attachment: fixed;
}

body.env-mode-non-prod {
  background: linear-gradient(to right, #a9bdc2, #71b280);
  background-attachment: fixed;
  .app-frame-sidebar {
    .zc-logo-dark {
      display: block;
    }
  }
}

// .MuiIconButton-sizeSmall {
//   padding: 7px !important;
//   font-size: 1.3rem !important;
// }

.dialog-bulk-status-transition {
  .MuiDialogContent-root {
    min-width: 420px;
    max-width: 420px;
  }
  .MuiChip-root {
    margin-right: 0.25rem;
  }
  .MuiAlert-root {
    margin-top: 0.5rem;
  }
  p.selection-msg {
    margin-bottom: 1.5rem;
  }
  .date-input-wrapper {
    padding: 1.5rem 0 0.75rem;
  }
  .loading-wrapper {
    text-align: center;
  }
}

.dialog-recipient {
  .dialog-titlebar,
  .dialog-footerbar {
    background: #e9e9e9;
  }
  .dialog-pseudo-title {
    width: 100%;
    margin: 0;
    background: #e9e9e9;
    h2 {
      margin: 0;
      padding: 0 0.5rem;
      font-size: 130%;
      text-transform: capitalize;
      font-weight: normal;
    }
  }
  .dialog-text {
    max-width: fit-content;
  }
  .dialog-footerbar {
    padding: 1rem;
  }
  .dialog-recipient-body {
    width: 90vw;
    max-width: 1200px;
    padding: 1rem 1.5rem;
    fieldset.outer-fs {
      padding: 1rem;
      border: 1px solid #ccc;
      border-radius: 8px;
      margin-top: 1.25rem;
      legend.outer-fs-legend {
        line-height: 1;
        text-transform: uppercase;
        margin: 0;
        font-size: 90%;
        display: inline-block;
        border: 0;
        width: auto;
        padding: 0.25rem 0.5rem;
        background: #f1f1f1;
        border-radius: 3px;
        border: 1px solid #ccc;
      }
    }
    .notifiable-emails {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      h5 {
        grid-column: 1 / -1;
        margin-bottom: 0;
      }
    }
    .last-updated-at {
      font-size: 80%;
      opacity: 0.8;
      display: block;
      padding-top: 0.5rem;
    }
  }

  .tab-wrap {
    background: #263f52;
    color: #fff;
    margin: -1rem -1.5rem 1.5rem;
    .MuiTabs-root {
      .MuiTab-wrapper {
        white-space: nowrap;
      }
    }
  }
}

// SASS vars
$rowHighlightColor: #cbf7d3;
$activeGroupHighlightColor: #72ca82;

// This sucks, but we can't use position:sticky for the group display without addressing positioning issues of
// nodes ABOVE our component. So the component is in charge of adding/removing these classes upon mounting/dismounting.
html.savings-review-v2-page {
  #tzc-main {
    overflow: initial;
    padding-bottom: 0;

    > div {
      position: relative;
    }
  }
}

.service-review-v2 {
  padding-bottom: 2rem;

  .custom-filters {
    flex-grow: 1;
    display: flex;
    margin-bottom: 1rem;

    .clear-icon {
      cursor: pointer;
      opacity: 0.5;
      transition: opacity 100ms ease-in;

      &:hover {
        opacity: 1;
      }
    }
  }

  .groups-table {
    margin-bottom: 1rem;
    .active-group {
      background: $rowHighlightColor;
      .cell-group-nr {
        background: $activeGroupHighlightColor;
      }
    }
  }

  .lines-table {
    .MuiDataGrid-row {
      cursor: pointer;
    }
    .active-line-in-active-group {
      background: $rowHighlightColor !important;
    }
    .active-line-outside-active-group {
      background: yellow !important;
    }
  }

  .group-info-container {
    background: #333;
    color: #fff;
    margin-bottom: 1rem;
    position: sticky;
    top: calc(60px + 1rem);
    z-index: 99;

    .MuiInput-underline {
      &:hover:not(.Mui-disabled):before {
        border-bottom-color: #ccc;
      }
      &::before {
        border-bottom-color: #ccc;
      }
      input,
      textarea {
        color: #fff;
      }
    }

    .MuiCheckbox-root {
      color: rgba(255, 255, 255, 0.54);
      &.Mui-checked {
        color: #f50057;
      }
    }

    .helper-text {
      display: block;
      font-size: 80%;
      padding: 0.25rem 0;
    }

    aside.group-info {
      padding: 1rem;
      font-size: 90%;

      .data-display {
        line-height: 1.4;
        border-bottom: 1px solid #aaa;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;

        span {
          display: inline-block;
          padding-right: 0.75rem;
          strong {
            display: inline-block;
            padding-right: 0.15rem;
            opacity: 0.65;
            text-decoration: underline;
          }
        }

        .claim-link {
          a {
            text-decoration: none;
            color: rgb(157, 195, 252);
            position: relative;
            display: inline-block;
            svg {
              position: absolute;
              left: 90%;
              bottom: 50%;
            }
          }
        }
      }

      .gridded {
        display: grid;
        grid-template-columns: max-content max-content max-content max-content 1fr;
        grid-column-gap: 1rem;
        * {
          color: inherit;
        }

        .MuiInputLabel-root {
          white-space: nowrap;
        }
        .helper-text {
          font-size: 100%;
          white-space: nowrap;
        }

        .display-cost-key {
          width: max-content;
          strong {
            display: block;
            font-size: 11px;
            font-weight: normal;
            position: relative;
            top: -2px;
          }
          span {
            font-weight: bold;
            font-size: 110%;
            text-decoration: underline;
            display: block;
            padding: 0.25rem 0.45rem;
            background: rgba(255, 255, 255, 0.15);
            color: rgb(238, 227, 71);
            cursor: pointer;
            border-radius: 5px;
          }
          small {
            display: block;
            font-size: 90%;
            padding: 0.25rem;
            max-width: 300px;
          }
        }
      }
    }

    .save-review {
      grid-area: 1 / 7 / 8 / -1; //grid-column:1 / -1;
      button {
        margin-top: 0.5rem;
      }
    }

    .shortcut-buttons {
      position: absolute;
      top: calc(100% + 1rem);
      left: 0;
      width: 3rem;

      button {
        display: block;
        margin-bottom: 0.5rem;
        min-width: auto;
      }
    }
  }

  .line-review-container {
    min-height: 250px;
    padding-left: 4rem;
    margin-bottom: 1rem;
  }

  .btn-save-changed-lines {
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .power-user-tips {
    padding: 1rem;
    font-size: 85%;
    background: #e1e1e1;
    border-radius: 8px;

    strong {
      display: block;
      padding-bottom: 0.5rem;
    }

    ul {
      margin: 0;
      padding: 0.5rem 1.5rem;
    }

    code {
      border: 1px solid #c7254e;
    }
  }
}

// CostKeyQuickSearch is a modal, so it appears outside of the root of the component
#cost-key-quick-search {
  .MuiPaper-root {
    color: #fff;
    max-width: min-content;
    min-width: 600px;
    background: transparent;
    box-shadow: none;
  }
  .quick-search-container {
    width: 100%;
    .quick-search-input-wrap {
      background: rgba(0, 0, 0, 0.85);
      border-radius: 8px;
      color: #fff;
      .MuiInputBase-root {
        color: inherit;
      }
      input {
        color: inherit;
        padding: 1rem;
        font-size: 4rem;
        background: transparent;
        outline: none;
        border: 0;
      }
    }
    .quick-search-results {
      width: 100%;
      background: rgba(0, 0, 0, 0.75);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .content-display {
        max-width: 100%;
        padding: 1rem;
        border-bottom: 1px solid #ccc;
        cursor: pointer;
        &:hover {
          background: rgba(84, 143, 92, 0.856);
        }
        &:last-of-type {
          border-bottom: 0;
        }
      }
      .cost-key-code {
        display: block;
      }
      .descr {
        display: block;
        font-size: 80%;
        white-space: normal;
        padding: 0.25rem 0 0.25rem 0.5rem;
      }
      .price-info {
        padding-left: 0.5rem;
        font-size: 80%;
        strong {
          display: block;
        }
        .price-item {
          display: inline-block;
          margin-right: 0.5rem;
        }
        em {
          text-decoration: underline;
          font-style: normal;
        }
      }
    }
    .status-indicator-icon {
      font-size: 3rem;
      margin-right: 1rem;
    }

    &.ck-invalid {
      .status-indicator-icon {
        color: crimson;
      }
    }

    &.ck-found {
      .quick-search-input-wrap {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      .status-indicator-icon {
        color: #51a761;
      }
    }
  }
}

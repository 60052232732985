.engineering-only {
  h3 {
    margin-top: 0;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      padding: 0.25rem 0;
    }
  }
}

.dialogRoot {
  width: 450px;
}

.dialogWide {
  min-width: 850px;
}

.retryDialog {
  max-width: 90% !important;
}

.checkBox {
  height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    margin: 0;
  }
}

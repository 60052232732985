.invoice-date-inputs-by-next-status {
  width: 100%;
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  margin: 0 auto;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 1rem;

  strong {
    display: block;
    white-space: nowrap;
  }
}

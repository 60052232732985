.invoice-table-wrap {
  .invoice-table-subheader-items {
    &:empty {
      display: none;
    }
  }

  .invoice-data-table {
    tr > td {
      cursor: auto;
    }
  }
}
